var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-variants" },
    _vm._l(_vm.data, function (variant, variantIndex) {
      return _c(
        "ul",
        { key: variantIndex, staticClass: "list-variants" },
        _vm._l(variant, function (item, index) {
          return _c(
            "li",
            { key: index },
            [
              item.type === _vm.productVariantsType.HEX
                ? _c(
                    "span",
                    _vm._g(
                      {
                        staticClass: "circle",
                        class: {
                          "circle-hex":
                            _vm.isVariantHoverActive &&
                            item.id === _vm.activeVariantId,
                        },
                        style: { backgroundColor: item.value },
                      },
                      _vm.isSite
                        ? {
                            mouseenter: () => _vm.onHoverVariant(item),
                            mouseleave: () => _vm.onHoverVariant(null),
                          }
                        : {}
                    )
                  )
                : _vm._e(),
              _vm._v(" "),
              item.type === _vm.productVariantsType.TEXT_HEX
                ? _c("span", {
                    staticClass: "circle circle-text",
                    attrs: { "data-text": item.value },
                  })
                : _vm._e(),
              _vm._v(" "),
              item.type === _vm.productVariantsType.TEXT
                ? [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.value) +
                        "\n            "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }