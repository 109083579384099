var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("router-link", {
    attrs: { custom: "", to: _vm.to, target: _vm.target },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ navigate }) {
          return [
            _c(
              "div",
              {
                staticClass: "item-product",
                class: {
                  "item-product-sold-out":
                    _vm.flags && _vm.flags.stock.includes("SOLD_OUT"),
                },
                on: { click: navigate },
              },
              [
                _c(
                  "div",
                  { staticClass: "img" },
                  [
                    _c(
                      _vm.to ? "router-link" : "div",
                      {
                        tag: "component",
                        staticClass: "img-responsive img-responsive-1by1",
                        class: { transition: _vm.transition },
                        attrs: { to: _vm.to, target: _vm.target },
                      },
                      [
                        _c("base-img", {
                          staticClass: "image-primary",
                          attrs: {
                            src: _vm.getImageSrc(_vm.imageSrcPrimary),
                            alt: _vm.getProp("name"),
                            srcset: _vm.getImageSrcset(_vm.imageSrcPrimary),
                            sizes: _vm.getImageSizes(),
                            lazy: _vm.imageLoading === "lazy",
                          },
                        }),
                        _vm._v(" "),
                        _vm.imageSrcSecondary !== null
                          ? _c("base-img", {
                              staticClass: "image-secondary",
                              attrs: {
                                src: _vm.imageSrcSecondary,
                                alt: _vm.name,
                              },
                              nativeOn: {
                                load: function ($event) {
                                  return _vm.onImageSecondaryLoaded()
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$store.state.ui.switch.web.baseItemProduct.btnFavorites
                      ? [
                          _vm.btnAction ===
                          _vm.ITEM_PRODUCT_BTN_ACTION_TYPES.FAVORITE
                            ? _c("btn-favorite", {
                                staticClass: "btn-action",
                                attrs: {
                                  id: _vm.id,
                                  icon:
                                    _vm.btnFavoritesAction ===
                                      _vm.BTN_FAVORITES_ACTIONS.REMOVE &&
                                    "close",
                                  active: _vm.isProductFavorite,
                                  action: _vm.btnFavoritesAction,
                                },
                                on: {
                                  "btn-action": function ($event) {
                                    return _vm.$emit(
                                      "btn-favorites-action",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.btnAction ===
                          _vm.ITEM_PRODUCT_BTN_ACTION_TYPES.HYBRID
                            ? _c("btn-favorite-hybrid", {
                                staticClass: "btn-action",
                                attrs: {
                                  id: _vm.id,
                                  active: _vm.isProductFavorite,
                                },
                                on: {
                                  "btn-action": function ($event) {
                                    return _vm.$emit(
                                      "btn-favorites-action",
                                      $event
                                    )
                                  },
                                },
                              })
                            : _vm.btnAction ===
                              _vm.ITEM_PRODUCT_BTN_ACTION_TYPES.HYBRID_REMOVE
                            ? _c("btn-collection-product-remove", {
                                staticClass: "btn-action",
                                attrs: { "product-id": _vm.id },
                                on: {
                                  "btn-product-removed": function ($event) {
                                    return _vm.$emit(
                                      "btn-collection-product-removed"
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.productBadges.length
                      ? _c("base-list-badges", {
                          staticClass: "flex-column",
                          attrs: { data: _vm.productBadges },
                        })
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _vm.variants
                      ? _c("base-list-variants", {
                          attrs: {
                            data: _vm.variants,
                            "is-variant-hover-active": _vm.isVariantHoverActive,
                            "active-variant-id": _vm.activeVariantId,
                          },
                          on: { "hover-variant": _vm.onHoverVariant },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getProp("labels")
                      ? _c("base-list-labels", {
                          staticClass: "list-inline-wrap",
                          attrs: { data: _vm.getProp("labels") },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass: "title",
                        class: { "text-truncate": _vm.isMini },
                      },
                      [
                        _vm.to
                          ? _c("router-link", {
                              attrs: { to: _vm.to, target: _vm.target },
                              domProps: {
                                textContent: _vm._s(_vm.getProp("name")),
                              },
                            })
                          : [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.getProp("name")) +
                                  "\n                "
                              ),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.getProp("rating.score")
                      ? _c("base-form-rating", {
                          attrs: {
                            active: _vm.getProp("rating.score"),
                            "count-message": _vm.getProp("rating.count"),
                            size: "md",
                            readonly: "",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-ref-wrapper" },
                      [
                        _vm.priceConversion.completePrice
                          ? _c("price", {
                              staticClass: "price-ref",
                              attrs: {
                                "price-hybrid": "",
                                price: _vm.getProp(
                                  "priceConversion.completePrice.ref"
                                ),
                                currency: _vm.priceConversion.currency,
                              },
                            })
                          : _vm.completePrice.ref.price
                          ? _c("price", {
                              staticClass: "price-ref",
                              attrs: {
                                "ref-price": "",
                                "ref-price-label-long": false,
                                price: _vm.getProp("completePrice.ref"),
                                currency: _vm.currency,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("price", {
                      staticClass: "price-listing",
                      attrs: {
                        price: _vm.getProp("completePrice"),
                        currency: _vm.currency,
                      },
                    }),
                    _vm._v(" "),
                    _vm.getProp("notification")
                      ? _c("base-list-labels", {
                          staticClass: "label-notification",
                          attrs: { data: [_vm.getProp("notification")] },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.actionLabel
                  ? _c(
                      "div",
                      { staticClass: "actions" },
                      [
                        _c(
                          "base-btn",
                          {
                            attrs: { variant: "light", size: "sm" },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.$emit("btn-action")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.actionLabel) +
                                "\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }