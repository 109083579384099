var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c(
        "ul",
        { staticClass: "labels-list" },
        _vm._l(_vm.data, function (label, index) {
          return _c(
            "li",
            { key: `label-${index}`, staticClass: "labels-list-item" },
            [
              label.icon
                ? _c("base-icon", {
                    attrs: {
                      name: label.icon,
                      classes: label.variant !== "muted" ? label.variant : "",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", {
                class: [
                  `label label-${label.variant}`,
                  {
                    [`label-diamond label-diamond-${label.variant}`]:
                      !label.icon && label.variant !== "muted",
                  },
                ],
                domProps: { innerHTML: _vm._s(label.text) },
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }