<template>
    <div class="container-variants">
        <ul
            v-for="(variant, variantIndex) in data"
            :key="variantIndex"
            class="list-variants"
        >
            <li
                v-for="(item, index) in variant"
                :key="index"
            >
                <span
                    v-if="item.type === productVariantsType.HEX"
                    class="circle"
                    :class="{ 'circle-hex': isVariantHoverActive && item.id === activeVariantId }"
                    :style="{ backgroundColor: item.value }"
                    v-on="isSite
                        ? { mouseenter: () => onHoverVariant(item),
                            mouseleave: () => onHoverVariant(null) }
                        : {}"
                />
                <span
                    v-if="item.type === productVariantsType.TEXT_HEX"
                    class="circle circle-text"
                    :data-text="item.value"
                />
                <template
                    v-if="item.type === productVariantsType.TEXT"
                >
                    {{ item.value }}
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
import { PRODUCT_VARIANTS } from '../utils/constants';
import { sourceMixin } from '../utils/mixins';

export default {
    name: 'BaseListVariants',

    mixins: [
        sourceMixin,
    ],

    props: {
        data: {
            type: Array,
            default: () => ([]),
        },
        isVariantHoverActive: {
            type: Boolean,
            default: false,
        },
        activeVariantId: {
            type: Number,
            required: true,
        },
    },

    beforeCreate() {
        this.productVariantsType = PRODUCT_VARIANTS;
    },

    methods: {
        onHoverVariant(variant) {
            if (!this.isVariantHoverActive || !this.isSite) return;

            this.$emit('hover-variant', variant);
        },
    },
};
</script>

<style lang="scss" scoped>
@use "sass:map";

@import "./../scss/required";

.list-variants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    * + * {
        margin-left: map.get($spacers, 2);
    }
}

.container-variants > * + * {
    margin-top: map.get($spacers, 2);
}

.circle {
    @include media-breakpoint-up(sm) {
        width: 25px;
        height: 25px;
        border-width: 2px;

        &.circle-hex {
            border-color: $white;
            box-shadow: 0 0 .4rem $gray-700;
        }
    }
}

.circle-text {
    display: none;
    font-size: $font-size-xs;
    line-height: $font-size-xs;

    @include media-breakpoint-up(sm) {
        display: flex;
    }
}
</style>
