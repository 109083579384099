<template>
    <router-link
        v-slot="{ navigate }"
        custom
        :to="to"
        :target="target"
    >
        <div
            class="item-product"
            :class="{ 'item-product-sold-out': flags && flags.stock.includes('SOLD_OUT') }"
            @click="navigate"
        >
            <div class="img">
                <component
                    :is="to ? 'router-link' : 'div'"
                    :to="to"
                    class="img-responsive img-responsive-1by1"
                    :class="{ 'transition': transition }"
                    :target="target"
                >
                    <base-img
                        :src="getImageSrc(imageSrcPrimary)"
                        :alt="getProp('name')"
                        :srcset="getImageSrcset(imageSrcPrimary)"
                        :sizes="getImageSizes()"
                        :lazy="imageLoading === 'lazy'"
                        class="image-primary"
                    />
                    <base-img
                        v-if="imageSrcSecondary !== null"
                        :src="imageSrcSecondary"
                        :alt="name"
                        class="image-secondary"
                        @load.native="onImageSecondaryLoaded()"
                    />
                </component>
                <template v-if="$store.state.ui.switch.web.baseItemProduct.btnFavorites">
                    <btn-favorite
                        v-if="btnAction === ITEM_PRODUCT_BTN_ACTION_TYPES.FAVORITE"
                        :id="id"
                        :icon="btnFavoritesAction === BTN_FAVORITES_ACTIONS.REMOVE && 'close'"
                        :active="isProductFavorite"
                        :action="btnFavoritesAction"
                        class="btn-action"
                        @btn-action="$emit('btn-favorites-action', $event)"
                    />
                    <btn-favorite-hybrid
                        v-if="btnAction === ITEM_PRODUCT_BTN_ACTION_TYPES.HYBRID"
                        :id="id"
                        :active="isProductFavorite"
                        class="btn-action"
                        @btn-action="$emit('btn-favorites-action', $event)"
                    />
                    <btn-collection-product-remove
                        v-else-if="btnAction === ITEM_PRODUCT_BTN_ACTION_TYPES.HYBRID_REMOVE"
                        :product-id="id"
                        class="btn-action"
                        @btn-product-removed="$emit('btn-collection-product-removed')"
                    />
                </template>
                <base-list-badges
                    v-if="productBadges.length"
                    :data="productBadges"
                    class="flex-column"
                />
            </div>
            <div class="body">
                <base-list-variants
                    v-if="variants"
                    :data="variants"
                    :is-variant-hover-active="isVariantHoverActive"
                    :active-variant-id="activeVariantId"
                    @hover-variant="onHoverVariant"
                />
                <base-list-labels
                    v-if="getProp('labels')"
                    :data="getProp('labels')"
                    class="list-inline-wrap"
                />
                <h2
                    class="title"
                    :class="{ 'text-truncate': isMini }"
                >
                    <router-link
                        v-if="to"
                        :to="to"
                        :target="target"
                        v-text="getProp('name')"
                    />
                    <template v-else>
                        {{ getProp('name') }}
                    </template>
                </h2>
                <base-form-rating
                    v-if="getProp('rating.score')"
                    :active="getProp('rating.score')"
                    :count-message="getProp('rating.count')"
                    size="md"
                    readonly
                />
                <div class="price-ref-wrapper">
                    <price
                        v-if="priceConversion.completePrice"
                        price-hybrid
                        :price="getProp('priceConversion.completePrice.ref')"
                        :currency="priceConversion.currency"
                        class="price-ref"
                    />
                    <price
                        v-else-if="completePrice.ref.price"
                        ref-price
                        :ref-price-label-long="false"
                        :price="getProp('completePrice.ref')"
                        :currency="currency"
                        class="price-ref"
                    />
                </div>
                <price
                    :price="getProp('completePrice')"
                    :currency="currency"
                    class="price-listing"
                />
                <base-list-labels
                    v-if="getProp('notification')"
                    :data="[getProp('notification')]"
                    class="label-notification"
                />
            </div>
            <div
                v-if="actionLabel"
                class="actions"
            >
                <base-btn
                    variant="light"
                    size="sm"
                    @click.native.stop="$emit('btn-action')"
                >
                    {{ actionLabel }}
                </base-btn>
            </div>
        </div>
    </router-link>
</template>

<script>
import { defineAsyncComponent } from 'vue';

import {
    PRODUCT_BADGE_TYPE,
    SOURCE,
    BTN_FAVORITES_ACTIONS,
    ITEM_PRODUCT_BTN_ACTION_TYPES,
    BREAKPOINTS_DEVICES,
    BREAKPOINTS,
} from '../utils/constants';
import BaseListLabels from './BaseListLabels.vue';
import BaseListVariants from './BaseListVariants.vue';
import BaseFormRating from './BaseFormRating.vue';
import Price from './Price.vue';
import { btnFavoritesLabelMixin } from '../utils/mixins';
import { getSrcCF } from '../utils';

const BtnFavorite = defineAsyncComponent(() => import('./BtnFavorite.vue'));
const BtnFavoriteHybrid = defineAsyncComponent(() => import('./BtnFavoriteHybrid.vue'));
const BaseListBadges = defineAsyncComponent(() => import('./BaseListBadges.vue'));
const BtnCollectionProductRemove = defineAsyncComponent(() => import('./BtnCollectionProductRemove.vue'));

const breakpoints = {
    motoGPower: BREAKPOINTS_DEVICES.motoGPower,
    ...BREAKPOINTS,
};

export default {
    name: 'BaseItemProduct',

    components: {
        BaseListLabels,
        BaseListVariants,
        BaseFormRating,
        Price,
        BtnFavorite,
        BtnFavoriteHybrid,
        BaseListBadges,
        BtnCollectionProductRemove,
    },

    mixins: [
        btnFavoritesLabelMixin,
    ],

    props: {
        id: {
            required: true,
            type: Number,
        },
        imageSrc: {
            required: true,
            type: String,
        },
        imageSrcAmbient: {
            type: String,
            default: '',
        },
        imageLoading: {
            type: String,
            default: 'lazy',
        },
        name: {
            required: true,
            type: String,
        },
        price: {
            required: true,
            type: Number,
        },
        priceSth: { // TODO create a component to price-sth
            type: [Object, Boolean],
            default: false,
        },
        priceDecimal: {
            required: true,
            type: Object,
        },
        completePrice: {
            required: true,
            type: Object,
        },
        priceConversion: {
            type: Object,
            default: () => ({
                completePrice: null,
            }),
        },
        currency: {
            required: true,
            type: String,
        },
        rating: {
            type: Object,
            default: () => ({}),
        },
        flags: {
            type: Object,
            default: () => ({}),
        },
        slug: {
            type: [String, Boolean],
            default: false,
        },
        target: {
            type: String,
            default: null,
        },
        badges: {
            type: [Array, Boolean],
            default: false,
        },
        labels: {
            type: [Array, Boolean],
            default: false,
        },
        queryParams: {
            type: Object,
            default: () => ({}),
        },
        variants: {
            type: Array,
            default: undefined,
        },
        btnAction: {
            type: [Boolean, String],
            default: ITEM_PRODUCT_BTN_ACTION_TYPES.HYBRID,
        },
        btnFavoritesAction: {
            type: String,
            default: BTN_FAVORITES_ACTIONS.TOGGLE,
        },
        actionLabel: {
            type: String,
            default: null,
        },
        isMini: {
            type: Boolean,
            default: false,
        },
        isVariantHoverActive: {
            type: Boolean,
            default: false,
        },
        notification: {
            type: Object,
            default: null,
        },
        isInFeed: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['btn-action', 'btn-favorites-action', 'btn-collection-product-removed'],

    data: () => ({
        imageSrcSecondary: null,
        transition: false,
        isVariantHover: false,
        activeVariant: null,
    }),

    computed: {
        to() {
            if (!this.slug || (this.isVariantHover && !this.activeVariant.slug)) return false;

            let output = `/${this.slug}`;

            if (this.isVariantHover && this.activeVariant.slug) {
                output = `/${this.activeVariant.slug}`;
            }

            if (this.urlQueryString) output += `?${this.urlQueryString}`;

            return output;
        },
        urlQueryString() {
            if (!this.queryParams) return false;

            const paramPairs = Object.entries(this.queryParams);

            return paramPairs.reduce((acc, param, index) => (index === paramPairs.length - 1
                ? `${acc}${param[0]}=${param[1]}`
                : `${acc}${param[0]}=${param[1]}&`), '');
        },
        pageVariants() {
            const path = this.$route.fullPath;
            const data = this.$store.state.pages[path].variants;

            return data;
        },
        imageSrcPrimary() {
            return this.isVariantHover ? this.activeVariant.imageSrc : this.imageSrc;
        },
        activeVariantId() {
            return this.activeVariant?.id || this.id;
        },
        productBadges() {
            return this.isVariantHover
                ? this.activeVariant.badges
                : this.badges;
        },
    },

    created() {
        this.BTN_FAVORITES_ACTIONS = BTN_FAVORITES_ACTIONS;
        this.ITEM_PRODUCT_BTN_ACTION_TYPES = ITEM_PRODUCT_BTN_ACTION_TYPES;
    },

    mounted() {
        this.itemAddEventListener();
    },

    methods: {
        itemAddEventListener() {
            if (!this.imageSrcAmbient || this.$store.state.params.source !== SOURCE.SITE) {
                return;
            }

            this.onHoverIn();
        },
        onHoverIn() {
            this.imageSrcSecondary = this.imageSrcAmbient;
        },
        onImageSecondaryLoaded() {
            this.transition = true;
        },
        onHoverVariant(variant) {
            if (variant === null) return;

            this.activeVariant = this.pageVariants.find((item) => item.id === variant.id);
            this.isVariantHover = true;

            if (
                this.activeVariant?.imageSrcAmbient
                && this.activeVariant?.imageSrcAmbient !== this.imageSrcSecondary
            ) {
                this.imageSrcSecondary = this.activeVariant.imageSrcAmbient;
            }
        },
        getProp(path) {
            const pathKeys = path.split('.');

            if (pathKeys.length < 2) {
                return this.isVariantHover ? this.activeVariant[path] : this[path];
            }

            return this.isVariantHover
                ? pathKeys.reduce((acc, key) => acc && acc[key], this.activeVariant)
                : pathKeys.reduce((acc, key) => acc && acc[key], this);
        },
        getPriceSthValue() {
            return this.isVariantHover
                ? this.activeVariant.priceStrikethrough.value
                : this.priceSth.value;
        },
        getPriceSthPercentage() {
            return this.isVariantHover
                ? this.activeVariant.priceStrikethrough.percentage
                : this.priceSth.percentage;
        },
        getImageSrc(src) {
            return getSrcCF(src, '334x334');
        },
        getImageSrcset(src) {
            if (this.isInFeed) {
                return `${getSrcCF(src, '334x334')} 334w`;
            }

            return `
                ${getSrcCF(src, '172x172')} 172w,
                ${getSrcCF(src, '262x262')} 262w,
                ${getSrcCF(src, '334x334')} 334w
            `;
        },
        getImageSizes() {
            if (this.isInFeed) {
                return '334px';
            }

            return `
                (max-width: ${breakpoints.sm}px) 262px,
                (min-width: ${breakpoints.sm + 1}px) and (max-width: ${breakpoints.md}px) 334px,
                (min-width: ${breakpoints.md + 1}px) 262px
            `;
        },
    },
};
</script>

<style lang="scss" scoped>
// TODO move all in base-item-product.scss file
@import "./../scss/required";

:deep() {
    .list-badges {
        position: absolute;
        top: $spacer * .7;
        left: 0;
        max-width: 80%;
        transform: translateX(-1px);

        > * {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

.img {
    position: relative;
    padding: map-get($spacers, 2) map-get($spacers, 2) 0 map-get($spacers, 2);

    .image-secondary {
        opacity: 0;
    }

    &:hover {
        .transition {
            .image-secondary {
                opacity: 1;
                transition: $transition-fade;
            }
        }
    }

    .btn-action {
        position: absolute;
        top: map-get($spacers, 1);
        right: map-get($spacers, 1);
        width: 30px;
        height: 30px;
        background-color: $white;
        border: $border-width solid $gray-300;
        border-radius: 50%;

        @include media-breakpoint-up(sm) {
            top: map-get($spacers, 3);
            right: map-get($spacers, 3);
            width: 35px;
            height: 35px;
        }
    }

    .btn-favorites {
        &.active {
            opacity: 1;
        }

        &:focus {
            box-shadow: none;
        }
    }

    .btn-link {
        color: $text-muted;
    }

    @include media-breakpoint-up(sm) {
        padding: $spacer $spacer 0 $spacer;
    }
}

.body {
    position: relative;
    padding: map-get($spacers, 2);

    > *:not(:last-child) {
        margin-bottom: map-get($spacers, 2);
    }

    .container-variants {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
    }

    .list-inline-wrap :deep() {
        .list-inline-item {
            margin-right: calc(#{$list-inline-padding} / 2);
        }
    }

    @include media-breakpoint-up(sm) {
        padding: $spacer * 2 $spacer * 2 $spacer;

        .container-variants {
            transform: translateY(-50%);
        }
    }
}

.actions {
    padding-bottom: $spacer;
    text-align: center;
}

.title {
    display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
    overflow: hidden;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: calc(2em * $headings-line-height);

    &,
    a {
        color: $gray-900;
    }
}
</style>

<style lang="scss">
@import "./../scss/05-components/base-item-product";
</style>
